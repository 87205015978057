import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrls: ['./okta-callback.component.scss']
})
export class OktaCallbackComponent implements OnInit {
  error: string;

  constructor(private router: Router, private okta: OktaAuthService) { }

  async ngOnInit() {
    return this.okta.handleAuthentication().then(() => {
      /**
       * Navigate back to the saved uri, or root of application.
       */
      const fromUri = this.okta.getFromUri();
      window.location.replace(fromUri.uri);
    })
    .catch(e => {
      this.error = e.toString();
      this.router.navigate(['error', { id: '401' }]);
    });
  }

}
