import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: any[] = [];

  constructor() { }

  remove(notification) {
    this.notifications = this.notifications.filter(n => n !== notification);
  }
}
