import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarIdsService {

  constructor(private http: HttpClient) { }

  getCARIDs(accessToken: string, vendor: string) : Observable<string[]> {
    var url = environment.functionAPI + 'car-ids-api?vendor=' + vendor;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json",
                                  "Authorization": accessToken })
    };
    return this.http.get<string[]>(url, httpOptions);
  }

  getSites(accessToken: string) : Observable<string[]> {
    var url = environment.functionAPI + 'get-sites';

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json",
                                  "Authorization": accessToken })
    };
    return this.http.get<string[]>(url, httpOptions);
  }

  getForecastVendorsForSite(accessToken: string, site: string) : Observable<string[]> {
    var url = environment.functionAPI + 'get-forecast-vendors-for-site?site=' + site;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json",
                                  "Authorization": accessToken })
    };
    return this.http.get<string[]>(url, httpOptions);
  }
}
