import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import { ForecastsService } from 'src/app/services/forecasts.service';
import { CarIdsService } from 'src/app/services/car-ids.service';
import { OktaAuthService } from '@okta/okta-angular';
import { DateService } from 'src/app/services/date.service';
import { OutageCalculatorService } from 'src/app/services/outage-calculator.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-short-term-outage',
  templateUrl: './short-term-outage.component.html',
  styleUrls: ['./short-term-outage.component.scss']
})
export class ShortTermOutageComponent implements OnInit {

  accessToken: string;
  selectedSite: string;
  sites: string[];
  selectedVendor: string;
  vendors: string[];
  forecasts: any;
  losses: any;
  lossesLength: number;

  loading: boolean = true;
  serviceLoading: boolean = false;

	calculatorStartDate: NgbDateStruct;
	calculatorEndDate: NgbDateStruct;
  calculatorStartTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  calculatorEndTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  outsideDays: string;

  constructor(
    private carIdsService: CarIdsService, 
    private forecastsService: ForecastsService,
    private outageCalculatorService: OutageCalculatorService,
    private notificationService: NotificationService,
    private dateService: DateService,
    private okta: OktaAuthService) { }

  search = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 1),
      map(term => this.sites.filter(id => new RegExp(term, 'mi').test(id)).slice(0,10))
    )

  async ngOnInit() { 
    this.accessToken = await this.okta.getAccessToken();
    this.carIdsService.getSites(this.accessToken).subscribe(res => {
      this.sites = res;
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;

      this.notificationService.notifications.push({
        "message": "Something went wrong. Please contact an admin.",
        "class": "error"
      });
    });

    // Set date picker limits
    // Short term view should be current time to seven days advance
    const startDate = new Date();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 7);
    this.minDate = { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate()};
    this.maxDate = { year: endDate.getFullYear(), month: endDate.getMonth() + 1, day: endDate.getDate()};
    this.outsideDays = 'hidden';
  }

  siteSelect(site) {
    this.serviceLoading = true;
    this.selectedSite = site.item;
    this.selectedVendor = null;
    this.losses = null;

    forkJoin(this.carIdsService.getForecastVendorsForSite(this.accessToken, this.selectedSite), 
             this.forecastsService.getForecastsForSite(this.accessToken, this.selectedSite)).subscribe(res => {
      this.vendors = res[0];
      if (res[1]['error']) {
        this.serviceLoading = false;
      }
      else {
        var resJson = JSON.parse(res[1]);
        var resData = resJson['data'];
        if (resData.length > 0) {
          this.forecasts = resData;
        }
        else {
          this.forecasts = null;
        }
        this.serviceLoading = false;
      }
    }, error => {
      console.log(error);
      this.serviceLoading = false;

      this.notificationService.notifications.push({
        "message": "Something went wrong. Please contact an admin.",
        "class": "error"
      });
    });
  }
  
  vendorSelect(vendor) {
    this.selectedVendor = vendor;
  }

  calculateLoss() {
    if (this.calculatorStartDate && this.calculatorStartTime && this.calculatorEndDate && this.calculatorEndTime && this.selectedVendor) {
      this.serviceLoading = true;
      let startDate = this.dateService.returnDateString(this.calculatorStartDate, this.calculatorStartTime);
      let endDate = this.dateService.returnDateString(this.calculatorEndDate, this.calculatorEndTime);
  
      this.outageCalculatorService.calculateLoss(this.accessToken, this.selectedSite, startDate, endDate, 'forecast', null, this.selectedVendor).subscribe(res => {
          this.losses = res;
          this.lossesLength = Object.keys(res).length;
          this.serviceLoading = false;
      }, error => {
        console.log(error);
        this.serviceLoading = false;

        this.notificationService.notifications.push({
          "message": "Something went wrong. Please contact an admin.",
          "class": "error"
        });

        this.losses = {};
        this.lossesLength = 0;
      });
    }
    else {
      this.notificationService.notifications.push({
        "message": "Invalid parameters, please try again.",
        "class": "error",
        "delay": 3000
      });
    }
  }

}
