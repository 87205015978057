import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'outage-calc-detail',
  templateUrl: './outage-calc-detail.component.html',
  styleUrls: ['./outage-calc-detail.component.scss']
})
export class OutageCalcDetailComponent implements OnInit {

	outageKeys: any;
	outageProps: any;

	chartData: any[];

	firstProp: any;
	totalCost: any;
	totalGeneration: any;
	defaultMetrics: string[] = ["Lost Generation", "Wind Speed"];

	@Input() site: string;
	@Input() outageData: any;

  constructor() { }

  ngOnInit() {
	  this.outageKeys = Object.keys(this.outageData);
	  this.firstProp = this.outageKeys[0]
	  this.outageProps = Object.keys(this.outageData[this.firstProp]);
	  
	  this.totalCost = this.calculateAggregates(this.outageData, 'Total Loss');
	  this.totalGeneration = this.calculateAggregates(this.outageData, 'Lost Generation');
	  this.chartData = this.generateChartData(this.outageData);
  }

  calculateAggregates(outageData : any, aggKey: string) : any {
  	var entries = Object.entries(outageData);
  	const reducer = (acc, curr) => acc + curr[1][aggKey];
  	return entries.reduce(reducer, 0);
  }

  generateChartData(data: any) : any[] {
  	var chartValues = [];
  	var outageTimestamps = Object.keys(data);
  	this.outageProps.forEach(propertyKey => {
  		var chartProperty = { data: [], label: "" };
  		// chartProperty.data = [];
  		outageTimestamps.forEach(timestamp => {
  			chartProperty.data.push(data[timestamp][propertyKey]);
  		});
  		chartProperty.label = propertyKey;
  		chartValues.push(chartProperty);
	});

  	return chartValues;
  }

  downloadCsv(data: any) {
  	var csvHeader = Object.keys(data);
  	var csvString = "Time," + csvHeader.join(",");
  	this.outageProps.forEach(propertyKey => {
  		csvString += "\r\n" + propertyKey;
  		csvHeader.forEach(timestamp => {
  			csvString += "," + data[timestamp][propertyKey];
  		});
  	});
  	var blob = new Blob([csvString], {type: 'text/csv'})
  	saveAs(blob, this.site + "_Outage.csv");
  }

}
