import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { ShortTermOutageComponent } from './components/short-term-outage/short-term-outage.component';
import { LongTermOutageComponent } from './components/long-term-outage/long-term-outage.component';
import { OutageListComponent } from './components/outage-list/outage-list.component'; 
import { OutageCalculatorComponent } from './components/outage-calculator/outage-calculator.component';
import { OktaCallbackComponent } from './components/okta-callback/okta-callback.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  { path: '', redirectTo: '/outage-list', pathMatch: 'full' },
  { path: 'short-term-outage', component: ShortTermOutageComponent, canActivate: [OktaAuthGuard]},
  { path: 'long-term-outage', component: LongTermOutageComponent, canActivate: [OktaAuthGuard]},
  { path: 'outage-list', component: OutageListComponent, canActivate: [OktaAuthGuard]},
  { path: 'outage-calculator/:id', component: OutageCalculatorComponent, canActivate: [OktaAuthGuard]},
  { path: 'outage-calculator', component: OutageCalculatorComponent, canActivate: [OktaAuthGuard]},
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '/outage-list', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
