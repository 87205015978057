import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  convertDate(date: string) : NgbDateStruct {
  	var tempDate = new Date(date);
  	return { year: tempDate.getFullYear(), month: tempDate.getMonth() + 1, day: tempDate.getDate() }
  }

  convertTime(date: string) : NgbTimeStruct {
    var tempDate = new Date(date);
    return { hour: tempDate.getHours(), minute: tempDate.getMinutes(), second: 0 }
  }

  returnDateString(date: NgbDateStruct, time: NgbTimeStruct) {
    let returnDate = "";
    let year = date.year;
    let day = date.day;
    let month = date.month;
    let hour = time.hour;
    let minute = time.minute;

    // Handle date
    if (month < 10) {
      if (day < 10) {
        returnDate = "0" + month + "-0" + day + "-" + year;
      }
      else {
        returnDate = "0" + month + "-" + day + "-" + year;
      }
    }
    else {
      if (day < 10) {
        returnDate = month + "-0" + day + "-" + year;
      }
      else {
        returnDate = month + "-" + day + "-" + year;
      }
    }

    // Handle time
    if (hour < 10) {
      if (minute < 10) {
        returnDate = returnDate + " 0" + hour + ":0" + minute + ":00";
      }
      else {
        returnDate = returnDate + " 0" + hour + ":" + minute + ":00";
      }
    }
    else {
      if (minute < 10) {
        returnDate = returnDate + " " + hour + ":0" + minute + ":00";
      }
      else {
        returnDate = returnDate + " " + hour + ":" + minute + ":00";
      }    
    }

    return returnDate;
  }
}
