import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { CarIdsService } from 'src/app/services/car-ids.service';
import { OktaAuthService } from '@okta/okta-angular';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'short-term-outage-detail',
  templateUrl: './short-term-outage-detail.component.html',
  styleUrls: ['./short-term-outage-detail.component.scss']
})
export class ShortTermOutageDetailComponent implements OnInit {

	accessToken: string;
	vendors: string[];
	chartData: any[];

	latestPublishDatetime: Date;
	totalCost: any;
	totalGeneration: any;

	outageTimestamps: any[] = [];
	defaultMetrics: string[] = [];

	@Input() site: string;
	@Input() outageData: any;

  constructor(private carIdsService: CarIdsService, 
	private notificationService: NotificationService,
	private okta: OktaAuthService) { }

  async ngOnInit() {
	  this.accessToken = await this.okta.getAccessToken();
	  this.carIdsService.getForecastVendorsForSite(this.accessToken, this.site).subscribe(res => {
		  this.vendors = res;
		  this.latestPublishDatetime = this.outageData[0]['LatestPublishDatetime'];

		  this.totalCost = this.calculateAggregates(this.outageData, 'Total Loss');
		  this.totalGeneration = this.calculateAggregates(this.outageData, 'Lost Generation');

		  this.chartData = this.generateChartData(this.outageData);
	  }, error => {
        console.log(error);
        this.notificationService.notifications.push({
		  "message": "Something went wrong. Please contact an admin.",
		  "class": "error"
        });
      });
  }

  calculateAggregates(outageData: any, aggKey: string) : any {
  	var entries = Object.entries(outageData);
  	const reducer = (acc, curr) => acc + curr[1][aggKey];
  	return entries.reduce(reducer, 0);
  }

  generateChartData(data: any) : any[] {
	var chartValues = [];

	// Handle Timestamps
	data.forEach(item => {
		this.outageTimestamps.push(item['EndTime'].replace('.000Z', ''));
	});

	// Handle 3Tier
	if (this.vendors.includes('3Tier')) {
		var chartPropertyWS = { data: [], label: "3Tier Wind Speed" };
		var chartPropertyGen = { data: [], label: "3Tier Wind Generation" };

		data.forEach(item => {
			chartPropertyWS.data.push(item['3Tier Wind Speed']);
			chartPropertyGen.data.push(item['3Tier Wind Generation']);
		});
		this.defaultMetrics.push("3Tier Wind Speed");
		this.defaultMetrics.push("3Tier Wind Generation");
	
		chartValues.push(chartPropertyWS);
		chartValues.push(chartPropertyGen);
	}

	// Handle PRT
	if (this.vendors.includes('PRT')) {
		var chartPropertyGen = { data: [], label: "PRT Wind Generation" };

		data.forEach(item => {
			chartPropertyGen.data.push(item['PRT Wind Generation']);
		});
		this.defaultMetrics.push("PRT Wind Generation");
	
		chartValues.push(chartPropertyGen);
	}

	// Handle YES
	if (this.vendors.includes('YES')) {
		var chartPropertyGen = { data: [], label: "YES Wind Generation" };

		data.forEach(item => {
			chartPropertyGen.data.push(item['YES Wind Generation']);
		});
		this.defaultMetrics.push("YES Wind Generation");
	
		chartValues.push(chartPropertyGen);
	}

	// Handle YES
	if (this.vendors.includes('AWS TrueWind')) {
		var chartPropertyGen = { data: [], label: "AWS TrueWind Generation" };

		data.forEach(item => {
			chartPropertyGen.data.push(item['AWS TrueWind Generation']);
		});
		this.defaultMetrics.push("AWS TrueWind Generation");
	
		chartValues.push(chartPropertyGen);
	}

	// Handle Adapt2
	if (this.vendors.includes('Adapt2')) {
		var chartPropertyGen = { data: [], label: "RTO Wind Generation" };

		data.forEach(item => {
			chartPropertyGen.data.push(item['RTO Wind Generation']);
		});
		this.defaultMetrics.push("RTO Wind Generation");
	
		chartValues.push(chartPropertyGen);
	}

	// Handle Dayzer
	if (this.vendors.includes('Dayzer')) {
		var chartPropertyGen = { data: [], label: "Dayzer Wind Generation" };

		data.forEach(item => {
			chartPropertyGen.data.push(item['Dayzer Wind Generation']);
		});
		this.defaultMetrics.push("Dayzer Wind Generation");
	
		chartValues.push(chartPropertyGen);
	}

	return chartValues;
}

  downloadCsv(data: any) {
	// Handle timestamps
	var csvString = "Time";
	data.forEach(item => {
		csvString += "," + item['EndTime'].replace('.000Z', '');
	});

	// Handle vendors
	if (this.vendors.includes('3Tier')) {
		csvString += "\r\n3Tier Wind Speed";
		data.forEach(item => {
			csvString += "," + item['3Tier Wind Speed'];
		});

		csvString += "\r\n3Tier Wind Generation";
		data.forEach(item => {
			csvString += "," + item['3Tier Wind Generation'];
		});
	}
	if (this.vendors.includes('PRT')) {
		csvString += "\r\PRT Wind Generation";
		data.forEach(item => {
			csvString += "," + item['PRT Wind Generation'];
		});
	}
	if (this.vendors.includes('YES')) {
		csvString += "\r\nYES Wind Generation";
		data.forEach(item => {
			csvString += "," + item['YES Wind Generation'];
		});
	}
	if (this.vendors.includes('AWS TrueWind')) {
		csvString += "\r\nAWS TrueWind Generation";
		data.forEach(item => {
			csvString += "," + item['AWS TrueWind Generation'];
		});
	}
	if (this.vendors.includes('Adapt2')) {
		csvString += "\r\nRTO Wind Generation";
		data.forEach(item => {
			csvString += "," + item['RTO Wind Generation'];
		});
	}
	if (this.vendors.includes('Dayzer')) {
		csvString += "\r\nDayzer Wind Generation";
		data.forEach(item => {
			csvString += "," + item['Dayzer Wind Generation'];
		});
	}

  	var blob = new Blob([csvString], {type: 'text/csv'})
  	saveAs(blob, this.site + "_ShortTermOutage.csv");
  }

}
