import { Component } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'Invenergy Analytics Portal';

  _opened: boolean = false;
  _closeOnClickOutside: boolean = true;
  _showBackdrop: boolean = true;

  constructor(public router: Router, public oktaAuth: OktaAuthService) { }

  ngOnInit() { }

  login() {
    this.oktaAuth.loginRedirect('/forecasts');
  }

  logout() {
    this.oktaAuth.logout('/');
  }

  async isAuthenticated() {
    if (this.router.url == '/error') {
      return false;
    }
    else {
      return await this.oktaAuth.isAuthenticated();
    }
  }
  
  _toggleSidebar() {
    this._opened = !this._opened;
  }
}

