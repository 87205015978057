import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { OktaAuthModule } from '@okta/okta-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'ng-sidebar';

import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OutageListComponent } from './components/outage-list/outage-list.component';
import { OutageCalculatorComponent } from './components/outage-calculator/outage-calculator.component';
import { OutageCalcDetailComponent } from './components/outage-calc-detail/outage-calc-detail.component';
import { ShortTermOutageComponent } from './components/short-term-outage/short-term-outage.component';
import { ShortTermOutageDetailComponent } from './components/short-term-outage-detail/short-term-outage-detail.component';
import { LongTermOutageComponent } from './components/long-term-outage/long-term-outage.component';
import { WindGenerationChartComponent } from './components/wind-generation-chart/wind-generation-chart.component';
import { OktaCallbackComponent } from './components/okta-callback/okta-callback.component';
import { ErrorComponent } from './components/error/error.component';

const config = {
  issuer: 'https://invenergy.okta.com',
  redirectUri: environment.redirectUri,
  clientId: environment.clientId,
  scopes: ['openid', 'profile', 'groups']
}

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    OutageListComponent,
    OutageCalculatorComponent,
    OutageCalcDetailComponent,
    ShortTermOutageComponent,
    ShortTermOutageDetailComponent,
    LongTermOutageComponent,
    WindGenerationChartComponent,
    OktaCallbackComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    SidebarModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,
    FormsModule,
    OktaAuthModule.initAuth(config),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [OutageCalculatorComponent]
})
export class AppModule { }
