import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { PowerBiAuthService } from 'src/app/services/power-bi-auth.service'
import * as pbi from 'powerbi-client';
import { OktaAuthService } from '@okta/okta-angular';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-long-term-outage',
  templateUrl: './long-term-outage.component.html',
  styleUrls: ['./long-term-outage.component.scss']
})
export class LongTermOutageComponent implements OnInit {

  accessToken: string;
  pbiAccessToken: string;
  embedToken: string;

  groupid: string;
  reportid: string;

  constructor(private powerBiAuth: PowerBiAuthService, 
    private notificationService: NotificationService,
    private okta: OktaAuthService) { }

  async ngOnInit() {
    this.groupid = environment.groupId;
    this.reportid = environment.outageReportId;
    this.accessToken = await this.okta.getAccessToken();

    this.powerBiAuth.getAccessToken(this.accessToken).subscribe(res => {
      this.pbiAccessToken = res['access_token'];
      this.powerBiAuth.getEmbedToken(this.groupid, this.reportid, this.pbiAccessToken).subscribe(res => {
          this.embedToken = res['token'];
          this.getReport(this.reportid);
        }, error => {
          console.log(error);
          this.notificationService.notifications.push({
            "message": "Something went wrong. Please contact an admin.",
            "class": "error"
          });
        });
    }, error => {
      console.log(error);
      this.notificationService.notifications.push({
        "message": "Something went wrong. Please contact an admin.",
        "class": "error"
      });
    });
  }

  async getReport(reportid: string) {
    var url = 'https://app.powerbi.com/reportEmbed?reportId=' + reportid + '&groupId=' + environment.groupId;
    const config = {
      type: 'report',
      uniqueId: reportid,
      tokenType: pbi.models.TokenType.Embed,
      embedUrl: url,
      pageView: 'fitToWidth',
      accessToken: this.embedToken
    };

    const reportsContainer = <HTMLElement>document.getElementById(
      'reportsContainer'
    );

    const powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    const report = powerbi.embed(
      reportsContainer,
      config
    );

    report.off('loaded');
    report.on('loaded', function() {
    });

    report.off('pageChanged');
    report.on('pageChanged', e => {
    });

    report.on('error', function(event) {
      report.off('error');
      this.router.navigateByUrl('/error');
    });
  }

}
