import { Component, OnInit } from '@angular/core';
import { OutageListService } from 'src/app/services/outage-list.service';
import { OktaAuthService } from '@okta/okta-angular';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-outage-list',
  templateUrl: './outage-list.component.html',
  styleUrls: ['./outage-list.component.scss']
})
export class OutageListComponent implements OnInit {

	accessToken: string;
	loading: boolean = true;
	outages: any[];
	selectedOutage: any;
	page = 1;
	pageSize = 15;

	constructor(private outageListService: OutageListService, private notificationService: NotificationService, private okta: OktaAuthService) { }

	async ngOnInit() {
		this.accessToken = await this.okta.getAccessToken();
		this.outageListService.getOutages(this.accessToken).subscribe(async res => {
			this.outages = res;
			this.loading = false;
		}, error => {
			console.log(error);
			this.loading = false;
  
			this.notificationService.notifications.push({
			  "message": "Something went wrong. Please contact an admin.",
			  "class": "error"
			});
		});
	}
}
