import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TurbineTypeService {

  constructor(private http: HttpClient) { }

  getTurbineTypesForSite(accessToken: string, site: string) : Observable<any[]> {
    var url = environment.functionAPI + 'get-turbine-types-for-site?site=' + site;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json",
                                "Authorization": accessToken })
    };
    return this.http.get<any[]>(url, httpOptions);
  }
}
