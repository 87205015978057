import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { OutageCalculatorService } from 'src/app/services/outage-calculator.service';
import { OutageListService } from 'src/app/services/outage-list.service';
import { CarIdsService } from 'src/app/services/car-ids.service';
import { DateService } from 'src/app/services/date.service';
import { Observable } from 'rxjs';
import { OktaAuthService } from '@okta/okta-angular';
import { NotificationService } from 'src/app/services/notification.service';
import { TurbineTypeService } from 'src/app/services/turbine-type.service';

@Component({
  selector: 'app-outage-calculator',
  templateUrl: './outage-calculator.component.html',
  styleUrls: ['./outage-calculator.component.scss']
})

export class OutageCalculatorComponent implements OnInit {

  accessToken: string;
	outage: any = {siteName: ""};
  carIds: any[];
  turbineTypes: any[];
  losses: any;
  lossesLength: number;

  loading: boolean = true;
  siteLoading: boolean = false;
  calculateLoading: boolean = false;

	calculatorStartDate: NgbDateStruct;
	calculatorEndDate: NgbDateStruct;
  calculatorStartTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  calculatorEndTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  maxDate: NgbDateStruct;
  outsideDays: string;

  constructor(private route: ActivatedRoute, 
    private outageCalculatorService: OutageCalculatorService,
    private outageListService: OutageListService,
    private turbineTypeService: TurbineTypeService,
    private carIdsService: CarIdsService,
    private notificationService: NotificationService,
    private dateService: DateService,
    private okta: OktaAuthService) { }

  search = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 1),
      map(term => this.carIds.filter(id => new RegExp(term, 'mi').test(id)).slice(0,10))
    )

  async ngOnInit() {
    this.accessToken = await this.okta.getAccessToken();
    this.route.paramMap.subscribe(params => {
      if (params.has('id')){
          this.outageListService.getOutageFromId(this.accessToken, params.get('id')).subscribe(res => {
            this.calculatorStartDate = this.dateService.convertDate(res.ActualStart);
            this.calculatorEndDate = this.dateService.convertDate(res.ActualEnd);
            this.calculatorStartTime = this.dateService.convertTime(res.ActualStart);
            this.calculatorEndTime = this.dateService.convertTime(res.ActualEnd);
            this.outage = { siteName: res.SiteLookupId };
            this.siteSelect({ "item": this.outage.siteName});

            this.carIdsService.getSites(this.accessToken).subscribe(res => {
              this.carIds = res;
              this.loading = false;
            });
        }, error => {
          console.log(error);
          this.loading = false;

          this.notificationService.notifications.push({
            "message": "Something went wrong. Please contact an admin.",
            "class": "error"
          });
        });
      }
      else {
        this.carIdsService.getSites(this.accessToken).subscribe(res => {
          this.carIds = res;
          this.loading = false;
        }, error => {
          console.log(error);
          this.loading = false;

          this.notificationService.notifications.push({
            "message": "Something went wrong. Please contact an admin.",
            "class": "error"
          });
        });
      }
    });

    // Set date picker limits
    // Historic view should be max current time
    const endDate = new Date();
    this.maxDate = { year: endDate.getFullYear(), month: endDate.getMonth() + 1, day: endDate.getDate()};
    this.outsideDays = 'hidden';
  }

  siteSelect(site) {
    this.siteLoading = true;
    this.outage.siteName = site.item;
    this.turbineTypeService.getTurbineTypesForSite(this.accessToken, this.outage.siteName).subscribe(res => {
      res.forEach(type => {
        // Start each type as enabled
        type.enabled = true;
        type.amount = type.count;
      });
      this.turbineTypes = res;
      
      // Inform user there are no turbine types if length is 0
      if (this.turbineTypes.length == 0) {
        this.notificationService.notifications.push({
          "message": "This site has no turbine types. Please select another.",
          "class": "warning",
          "delay": 3000
        })
      }

      this.siteLoading = false;
    }, error => {
      console.log(error);
      this.siteLoading = false;
      this.notificationService.notifications.push({
        "message": "Something went wrong. Please contact an admin.",
        "class": "error"
      });
    });
  }

  toggleType(type) {
    type.amount = 0;
  }

  calculateLoss() {
    if (this.outage.siteName && this.calculatorStartDate && this.calculatorStartTime && this.calculatorEndDate && this.calculatorEndTime) {
      this.calculateLoading = true;
      let startDate = this.dateService.returnDateString(this.calculatorStartDate, this.calculatorStartTime);
      let endDate = this.dateService.returnDateString(this.calculatorEndDate, this.calculatorEndTime);

      // Handle turbine type input
      var turbineInput = [];
      this.turbineTypes.forEach(turbineType => {
        if (turbineType.enabled) {
          turbineInput.push(turbineType);
        }
      });
  
      this.outageCalculatorService.calculateLoss(this.accessToken, this.outage.siteName, startDate, endDate, 'actual', turbineInput).subscribe(res => {
          this.losses = res;
          this.lossesLength = Object.keys(res).length;
          this.calculateLoading = false;
      }, error => {
        console.log(error);
        this.calculateLoading = false;

        this.notificationService.notifications.push({
          "message": "Something went wrong. Please contact an admin.",
          "class": "error"
        });

        this.losses = {};
        this.lossesLength = 0;
      });
    }
    else {
      this.notificationService.notifications.push({
        "message": "Invalid parameters, please try again.", 
        "class": "error",
        "delay": 3000
      });
    }
  }

}
