import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of} from 'rxjs';
import { map, share } from 'rxjs/operators';
// import { of } from 'rxjs/observable';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OutageListService {

	data;
	observable;

  constructor(private http: HttpClient) { }

  getOutages(accessToken: string) : Observable<any[]> {
    var url = environment.functionAPI + 'outage-list-api';
    if (this.data){
    	return of(this.data);
    } else if (this.observable) {
    	return this.observable;
    } else {
    	const headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": accessToken })
    	this.observable = this.http.get(url, {headers: headers, observe: 'response'})
    	.pipe(map(response => {
    		this.observable = null;
    		if (response.status != 200){
    			return 'Request failed';
    		} else {
    			this.data = response.body;
    			return this.data;
    		}
    	}),
    	share());
    	return this.observable;
    }
  }

  getOutageFromId(accessToken: string, id: string) : Observable<any> {
  	if (!this.data){
  		return this.getOutages(accessToken).pipe(map((res) => {
  			this.data = res;
  			var picked = this.data.find(o => o.OutageId === id);
			if (picked) {
				return picked;
			}
  		}),
  		share());
  	} else {
  		var picked = this.data.find(o => o.OutageId === id);
		if (picked) {
			return of(picked)
		}
  	}

  }
}
