import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class NotificationsComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  ngOnInit() { }

}
