import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'wind-generation-chart',
  templateUrl: './wind-generation-chart.component.html',
  styleUrls: ['./wind-generation-chart.component.scss']
})
export class WindGenerationChartComponent implements OnInit {

	@Input() outageData: any[];
	@Input() outageTimestamps: any[];
	@Input() defaultMetrics: string[];
  
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
    	yAxes: [
	    	{
	    		id: "mwh",
	    		ticks: {
	    			beginAtZero: true
	    		},
	    		scaleLabel: {
	    			labelString: 'mWh',
	    			display: true
	    		},
	    		position: 'left'
	    	},
	    	{
	    		id: 'mps',
	    		ticks: {
	    			beginAtZero: true,
	    			suggestedMax: 30
	    		},
	    		scaleLabel: {
	    			labelString: 'm/s',
	    			display: true
	    		},
	    		position: 'right'
	    	}
    	],
    	xAxes: [
    		{
    			type: 'time',
    		}
    	]
    },
    hover: {
    	mode: "index",
    	intersect: false
    },
    tooltips: {
    	intersect: false,
    	mode: 'index',
    	callbacks: {
    		label: (tooltip, data) => {
    			var label = data.datasets[tooltip.datasetIndex].label || '';
    			if (label){
    				label += ": ";
    			}
    			label += tooltip.yLabel + " ";
    			// label += data.datasets[tooltip.datasetIndex].unit || '';
    			return label;
    		}
    	}
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#0c8d62',
      backgroundColor: 'rgba(0,0,255,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() { }

  ngOnInit() {
  	var transformedOutageData = []
  	this.outageData.forEach(metric => {
  		var transformMetric = metric;
  		transformMetric.fill = false
  		transformMetric.pointHoverBackgroundColor = 'rgba(255,255,255,1)'
  		if (transformMetric.label.includes("Wind Speed")){
  			transformMetric.yAxisID = "mps"
  			transformMetric.unit = "m/s"
  		} else {
  			transformMetric.yAxisID = "mwh"
  			transformMetric.unit = "MWh"
  		}
  		if (this.defaultMetrics.includes(transformMetric.label) ){
  			transformedOutageData.push(transformMetric);
  		}
  		
  	})
  	this.outageData = transformedOutageData;
  }

}
