import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PowerBiAuthService {
  constructor(private http: HttpClient) { }

  getAccessToken(accessToken: string): Observable<Object> {
      var url = environment.functionAPI + 'pbi-token-api';
      const httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json", "Authorization": accessToken })
      };
      return this.http.get<Object>(url, httpOptions);
  }

  getEmbedToken(groupid: string, reportid: string, accessToken: string) : Observable<Object> {
    var url = 'https://api.powerbi.com/v1.0/myorg/groups/' + groupid + '/reports/' + reportid + '/GenerateToken';
    var body = { "accessLevel": "View" };
  
    var reqHeaders = {'Authorization': "Bearer " + accessToken,
                      'Content-Type': 'application/json'};

    return this.http.post<Object>(url, body, {headers: reqHeaders});
  }
}
